import { api } from '../../../utils';
import _ from 'lodash';

// ------------------------------------
// Constants
// ------------------------------------
const CANCEL_FETCHING = 'cancelFetching';
const CANCEL_FETCHING_EXPENSES = 'cancelFetchingExpenses';
const CANCEL_FETCHING_EXPENSES_TYPE = 'cancelFetchingExpensesType';

const REQUEST_EXPENSES_TYPES = 'requestExpensesTypes';
const RECEIVE_EXPENSES_TYPES = 'receiveExpensesTypes';

const RECEIVE_EXPENSE = 'receiveExpense';
const RECEIVE_CREATE_EXPENSE = 'receiveCreateExpense';
const RECEIVE_UPDATED_EXPENSE = 'receiveUpdateExpense';
const RECEIVE_DELETED_EXPENSE = 'receiveDeletedExpense';

const REQUEST_EXPENSES = 'requestExpenses';
const RECEIVE_BULK_UPDATED_EXPENSES = 'receiveBulkUpdatedExpenses';

const REQUEST_EXPENSES_TYPE = 'requestExpensesType';
const RECEIVE_EXPENSES_TYPE = 'receiveExpensesType';

const RECEIVE_UPDATED_EXPENSES_TYPE = 'receiveUpdatedExpensesType';
const RECEIVE_CREATE_EXPENSES_TYPE = 'receiveCreateExpensesType';
const RECEIVE_DELETE_EXPENSES_TYPE = 'receiveDeleteExpensesType';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchExpensesTypes = () => (dispatch) => {
    dispatch(requestExpensesTypes());
    return api('/v4/admin/expenses/types', {
        method: 'GET'
    }, dispatch)
        .then(res => {
            if (res && res.types != null && Array.isArray(res.types)) {
                return dispatch(receiveExpensesTypes(res.types.sort((a, b) => a.sort - b.sort)));
            } else {
                return  dispatch(cancelFetching());
            }
        });
};

export const fetchExpensesType = (id) => (dispatch) => {
    id && dispatch(requestExpensesType());
    return id && api('/v4/admin/expenses/types/'+ id + '?with[0]=expenses', {
        method: 'GET'
    }, dispatch)
        .then(res => {
            if (res) {
                dispatch(receiveExpensesType(res));
            } else {
                dispatch(cancelFetchingExpensesType());
            }
        });
};

export const storeExpensesType = (data) => (dispatch) => {
    if (data) {
        dispatch(requestExpensesType());
        if (data?.icon) {
            return api('/v4/admin/svg-images', {
                    method: 'POST',
                    body: data?.icon
                },
                dispatch, true)
                .then(response => {
                    if (response) {
                        data.icon = response?.id;
                        return api('/v4/admin/expenses/types', {
                            method: 'POST',
                            body: JSON.stringify(data)
                        }, dispatch)
                            .then(res => {
                                if (res) {
                                    dispatch(receiveExpensesTypeStore(res));
                                    return res?.id;
                                } else {
                                    dispatch(cancelFetchingExpensesType());
                                    return false;
                                }
                            })
                            .catch(() => {
                                dispatch(cancelFetchingExpensesType());
                                return false;
                            });
                    } else {
                        dispatch(cancelFetchingExpensesType());
                        return false;
                    }
                });
        } else {
            return api('/v4/admin/expenses/types', {
                method: 'POST',
                body: JSON.stringify(data)
            }, dispatch)
                .then(res => {
                    if (res) {
                        dispatch(receiveExpensesTypeStore(res));
                        return res?.id;
                    } else {
                        dispatch(cancelFetchingExpensesType());
                        return false;
                    }
                })
                .catch(() => {
                    dispatch(cancelFetchingExpensesType());
                    return false;
                });
        }
    } else {
        return null;
    }
};

export const updateExpensesType = (id, data) => (dispatch) => {
    if (id && data) {
        dispatch(requestExpensesType());
        if (data?.icon) {
            return api('/v4/admin/svg-images', {
                    method: 'POST',
                    body: data?.icon
                },
                dispatch, true)
                .then(response => {
                    if (response) {
                        data.icon = response?.id;
                        return api('/v4/admin/expenses/types/' + id, {
                            method: 'PUT',
                            body: JSON.stringify(data)
                        }, dispatch)
                            .then(res => {
                                if (res) {
                                    dispatch(receiveExpensesTypeUpdate(res));
                                    return true;
                                } else {
                                    dispatch(cancelFetchingExpensesType());
                                    return false;
                                }
                            })
                            .catch(() => {
                                dispatch(cancelFetchingExpensesType());
                                return false;
                            });
                    } else {
                        dispatch(cancelFetchingExpensesType());
                        return false;
                    }
                });
        } else {
            return api('/v4/admin/expenses/types/' + id, {
                method: 'PUT',
                body: JSON.stringify(data)
            }, dispatch)
                .then(res => {
                    if (res) {
                        dispatch(receiveExpensesTypeUpdate(res));
                        return true;
                    } else {
                        dispatch(cancelFetchingExpensesType());
                        return false;
                    }
                })
                .catch(() => {
                    dispatch(cancelFetchingExpensesType());
                    return false;
                });
        }
    }
};

export const bulkUpdateExpensesTypes = (typesDataUpdate) => (dispatch) => {
    typesDataUpdate && dispatch(requestExpensesTypes());
    return typesDataUpdate && api('/v4/admin/expenses/types/bulk-update', {
        method: 'PUT',
        body: JSON.stringify(typesDataUpdate)
    }, dispatch)
        .then(res => {
            if (res && res.types != null && Array.isArray(res.types)) {
                dispatch(receiveExpensesTypes(res.types.sort((a, b) => a.sort - b.sort)));
            } else {
                dispatch(cancelFetching());
                return false;
            }
        })
        .catch(() => {
            dispatch(cancelFetching());
            return false;
        });
}

export const deleteExpensesType = (id) => (dispatch) => {
    id && dispatch(requestExpensesType());
    return id && api('/v4/admin/expenses/types/' + id, {
        method: 'DELETE'
    }, dispatch)
        .then(res => {
            if (res) {
                dispatch(receiveExpensesTypeDelete(res, id));
                return res;
            } else {
                dispatch(cancelFetchingExpensesType());
                return false;
            }
        })
        .catch(() => {
            dispatch(cancelFetchingExpensesType());
            return false;
        });
};

export const deleteExpenseDisplays = (id) => (dispatch) => {
    id && dispatch(requestExpenses());
    return id && api('/v4/admin/expenses/' + id + '/display', {
        method: 'DELETE'
    }, dispatch)
        .then(res => {
            if (res) {
                dispatch(fetchExpense(id));
            } else {
                dispatch(cancelFetchingExpenses());
            }
        })
        .catch(() => {
            dispatch(cancelFetchingExpenses());
            return false;
        });
};

export const updateExpenseDisplays = (id, data) => (dispatch) => {
    id && dispatch(requestExpenses());
    return id && api('/v4/admin/expenses/' + id + '/display', {
        method: 'PUT',
        body: JSON.stringify(data)
    }, dispatch)
        .then(res => {
            if (res) {
                dispatch(fetchExpense(id));
            } else {
                dispatch(cancelFetchingExpenses());
            }
        })
        .catch(() => {
            dispatch(cancelFetchingExpenses());
            return false;
        });
};

export const fetchExpense = (id) => (dispatch) => {
    id && dispatch(requestExpenses());
    return id && api('/v4/admin/expenses/' + id + '?with[0]=expenseType&with[1]=expenseFields&with[2]=expenseDisplay', {
        method: 'GET'
    }, dispatch)
        .then(res => {
            if (res) {
                dispatch(receiveExpense(res));
            } else {
                dispatch(cancelFetchingExpenses());
            }
        }).catch(() => {
            dispatch(cancelFetchingExpenses());
            return false;
        });
};

export const storeExpense = (data) => (dispatch) => {
    data && dispatch(requestExpenses());
    return data && api('/v4/admin/expenses', {
        method: 'POST',
        body: JSON.stringify(data)
    }, dispatch)
        .then(res => {
            if (res) {
                dispatch(receiveCreateExpense(res));
                return res;
            } else {
                dispatch(cancelFetchingExpenses());
                return null
            }
        });
};

export const updateExpense = (id, data) => (dispatch) => {
    id && data && dispatch(requestExpenses());
    return id && data && api('/v4/admin/expenses/' + id + '?with[0]=expenseType&with[1]=expenseFields&with[2]=expenseDisplay', {
        method: 'PUT',
        body: JSON.stringify(data)
    }, dispatch)
        .then(res => {
            if (res) {
                dispatch(receiveUpdateExpense(res));
                return res;
            } else {
                dispatch(cancelFetchingExpenses());
                return null;
            }
        });
};

export const bulkUpdateExpenses = (expensesData, typeId) => (dispatch) => {
    typeId && expensesData && dispatch(requestExpenses());
    return expensesData && api('/v4/admin/expenses/types/' + typeId + '/bulk-update', {
        method: 'PUT',
        body: JSON.stringify(expensesData)
    }, dispatch)
        .then(res => {
            if (res) {
                dispatch(receiveExpensesTypeUpdateExpenses(res));
            } else {
                dispatch(cancelFetchingExpenses());
                return false;
            }
        }).catch(() => {
            dispatch(cancelFetchingExpenses());
            return false;
        });
};

export const deleteExpense = (id) => (dispatch) => {
    id &&  dispatch(requestExpenses());
    return id && api('/v4/admin/expenses/' + id, {
        method: 'DELETE'
    }, dispatch)
        .then(res => {
            if (res) {
                dispatch(receiveDeletedExpense(id));
                return true;
            } else {
                dispatch(cancelFetchingExpenses());
                return false;
            }
        });
};

export const clearExpenseType = () => (dispatch) => {
    dispatch(receiveExpensesTypeStore(null));
}

export const clearExpense = () => (dispatch) => {
    dispatch(receiveExpense(null));
}

function cancelFetching() {
    return {
        type: CANCEL_FETCHING
    };
}

function cancelFetchingExpenses() {
    return {
        type: CANCEL_FETCHING_EXPENSES
    };
}

function cancelFetchingExpensesType() {
    return {
        type: CANCEL_FETCHING_EXPENSES_TYPE
    };
}

function requestExpensesTypes() {
    return {
        type: REQUEST_EXPENSES_TYPES
    };
}

function requestExpensesType() {
    return {
        type: REQUEST_EXPENSES_TYPE
    };
}

function requestExpenses() {
    return {
        type: REQUEST_EXPENSES
    };
}

function receiveExpensesTypes(res) {
    return {
        type: RECEIVE_EXPENSES_TYPES,
        payload: res
    };
}

function receiveExpense(res) {
    return {
        type: RECEIVE_EXPENSE,
        payload: res
    };
}

function receiveCreateExpense(res) {
    return {
        type: RECEIVE_CREATE_EXPENSE,
        payload: res
    };
}

function receiveUpdateExpense(res) {
    return {
        type: RECEIVE_UPDATED_EXPENSE,
        payload: res
    };
}

function receiveDeletedExpense(id) {
    return {
        type: RECEIVE_DELETED_EXPENSE,
        payload: id
    };
}

function receiveExpensesType(res) {
    return {
        type: RECEIVE_EXPENSES_TYPE,
        payload: res
    };
}

function receiveExpensesTypeUpdate(res) {
    return {
        type: RECEIVE_UPDATED_EXPENSES_TYPE,
        payload: res
    };
}

function receiveExpensesTypeUpdateExpenses(expensesType) {
    return {
        type: RECEIVE_BULK_UPDATED_EXPENSES,
        payload: expensesType
    };
}

function receiveExpensesTypeStore(res) {
    return {
        type: RECEIVE_CREATE_EXPENSES_TYPE,
        payload: res
    };
}

function receiveExpensesTypeDelete(res, id) {
    return {
        type: RECEIVE_DELETE_EXPENSES_TYPE,
        payload: {
            result: res,
            id: id
        }
    };
}

export const actions = {
    fetchExpensesTypes,
    fetchExpensesType,
    fetchExpense,
    clearExpense,
    clearExpenseType,
    storeExpensesType,
    updateExpensesType,
    deleteExpensesType,
    storeExpense,
    updateExpense,
    deleteExpense,
    bulkUpdateExpensesTypes,
    bulkUpdateExpenses
};
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [CANCEL_FETCHING]: (state) => {
        return ({ ...state, fetching: false });
    },
    [CANCEL_FETCHING_EXPENSES]: (state) => {
        return ({ ...state, fetchingExpenses: false });
    },
    [CANCEL_FETCHING_EXPENSES_TYPE]: (state) => {
        return ({ ...state, fetchingExpensesType: false });
    },
    [REQUEST_EXPENSES_TYPES]: (state) => {
        return ({ ...state, fetching: true });
    },
    [REQUEST_EXPENSES]: (state) => {
        return ({ ...state, fetchingExpenses: true });
    },
    [REQUEST_EXPENSES_TYPE]: (state) => {
        return ({ ...state, fetchingExpensesType: true });
    },
    [RECEIVE_EXPENSES_TYPES]: (state, action) => {
        return ({ ...state, expensesTypes: action.payload, fetching: false });
    },
    [RECEIVE_EXPENSE]: (state, action) => {
        return ({ ...state, expense: action.payload, fetchingExpenses: false });
    },
    [RECEIVE_CREATE_EXPENSE]: (state, action) => {
        let expensesType = _.cloneDeep(state.expensesType);
        if (expensesType) {
            expensesType.expenses = [ ...(expensesType?.expenses || []), action.payload];
        }
        return ({
            ...state,
            expense: action.payload,
            expensesType: expensesType,
            expensesTypes: null,
            fetchingExpenses: false
        });
    },
    [RECEIVE_UPDATED_EXPENSE]: (state, action) => {
        let expensesType = _.cloneDeep(state.expensesType);
        if (expensesType) {
            const expenses = expensesType?.expenses?.map(item => {
                if (item.id === action.payload?.id) {
                    return action.payload;
                }
                else {
                    return item
                }
            })
            expensesType.expenses = expenses ? [...expenses] : [];
        }

        return ({
            ...state,
            expense: action.payload,
            expensesType: expensesType,
            expensesTypes: null,
            fetchingExpenses: false
        });
    },

    [RECEIVE_DELETED_EXPENSE]: (state, action) => {
        let expensesType = _.cloneDeep(state.expensesType);
        if (expensesType) {
            const expenses = expensesType?.expenses?.filter(item => item.id !== action.payload)
            expensesType.expenses = expenses ? [...expenses] : [];
        }

        return ({
            ...state,
            expensesTypes: null,
            expensesType: expensesType,
            fetchingExpenses: false
        });
    },
    [RECEIVE_EXPENSES_TYPE]: (state, action) => {
        return ({ ...state, expensesType: action.payload, fetchingExpensesType: false });
    },
    [RECEIVE_UPDATED_EXPENSES_TYPE]: (state, action) => {
        let expensesTypes = state.expensesTypes ? _.cloneDeep(state.expensesTypes) : null;

        expensesTypes = expensesTypes?.map(item => {
            if (item.id === action.payload.id) {
                return { ...item, ...action.payload }
            }
            return item;
        });

        return ({
            ...state,
            expensesType: action.payload,
            expensesTypes: expensesTypes,
            fetchingExpensesType: false
        });
    },
    [RECEIVE_BULK_UPDATED_EXPENSES]: (state, action) => {
        let expensesType = state.expensesType ? _.cloneDeep(state.expensesType) : null;

        if (expensesType && (expensesType?.id === action.payload?.id)) {
            expensesType = action.payload;
        }

        return ({
            ...state,
            expensesType: expensesType,
            fetchingExpenses: false
        });
    },
    [RECEIVE_CREATE_EXPENSES_TYPE]: (state, action) => {
        let expensesTypes = state.expensesTypes ? _.cloneDeep(state.expensesTypes) : null;
        if (action.payload?.id) {
            expensesTypes = [
                ...expensesTypes,
                action.payload
            ];
        }

        return ({
            ...state,
            expensesType: action.payload,
            expensesTypes: expensesTypes,
            fetchingExpensesType: false
        });
    },
    [RECEIVE_DELETE_EXPENSES_TYPE]: (state, action) => {
        let expensesTypes = state.expensesTypes ? _.cloneDeep(state.expensesTypes) : null;
        if (action.payload?.result && expensesTypes) {
            expensesTypes = [...expensesTypes?.filter(item => item.id !== action.payload?.id)];
        }

        return ({
            ...state,
            expensesTypes: expensesTypes,
            fetchingExpensesType: false
        });
    },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    fetching: false,
    expensesTypes: null,
    expensesType: null,
    expense: null,
    fetchingExpenses: false,
    fetchingExpensesType: false
};

export default function ExpensesReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
