import React, { lazy, Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ExpensesReducer from './modules/expenses.reducer';
import PropTypes from 'prop-types';
import ExpensesLayout from '../../layouts/ExpensesLayout';

const LazyExpenses = lazy(() => import('./modules/Expenses.container'));
const LazyExpenseEditor = lazy(() => import('./components/ExpenseEditor'));
const LazyExpenseFieldsEditor = lazy(() => import('./components/ExpenseFieldsEditor'));
const LazyExpenseViewsEditor = lazy(() => import('./components/ExpenseViewsEditor'));
const LazyExpenseTypesEditor = lazy(() => import('./components/ExpenseTypesEditor'));

class Expenses extends Component {
    static propTypes = {
        store: PropTypes.object
    };

    render() {
        this.props.store.injectReducer('expenses', ExpensesReducer);
        return (
            <Switch>
                <Route path='/expenses-types'>
                    <ExpensesLayout>
                        <Route exact path='/expenses-types' component={LazyExpenses} />
                        <Route path='/expenses-types'>
                            <Switch>
                                <Route exact path='/expenses-types/create' component={LazyExpenseTypesEditor} />
                                <Route exact path='/expenses-types/:id' component={LazyExpenseTypesEditor} />
                                <Route exact path='/expenses-types/:id/expense/create' component={LazyExpenseEditor} />
                                <Route path='/expenses-types/:id/expense' >
                                    <Switch>
                                        <Route exact path='/expenses-types/:id/expense/:expenseId/info'  component={LazyExpenseEditor} />
                                        <Route exact path='/expenses-types/:id/expense/:expenseId/fields' component={LazyExpenseFieldsEditor} />
                                        <Route exact path='/expenses-types/:id/expense/:expenseId/views' component={LazyExpenseViewsEditor} />
                                        <Redirect to='/expenses-types/:id/expense/:expenseId/info' from='/expenses-types/:id/expense/:expenseId' />
                                    </Switch>
                                </Route>
                            </Switch>
                        </Route>
                    </ExpensesLayout>
                </Route>
            </Switch>
        );
    }
}

export default Expenses;
