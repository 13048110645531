import { api, httpBuildQuery } from '../../../utils';
import _ from 'lodash';

// ------------------------------------
// Constants
// ------------------------------------
const REQUEST_PACKETS = 'requestPackets';
const RECEIVE_PACKETS = 'receivePackets';
const CANCEL_FETCHING_PACKETS = 'cancelFetchingPackets';

const REQUEST_CYCLES = 'requestCycles';
const RECEIVE_CYCLES = 'receiveCycles';
const CANCEL_FETCHING_CYCLES = 'cancelFetchingCycles';

const REQUEST_PACKET = 'requestPacket';
const RECEIVE_PACKET = 'receivePacket';
const RECEIVE_CREATE_PACKET = 'receiveCreatePacket';
const RECEIVE_UPDATED_PACKET = 'receiveUpdatePacket';
const RECEIVE_DELETED_PACKET = 'receiveDeletedPacket';
const CANCEL_FETCHING_PACKET = 'cancelFetchingPacket';

const REQUEST_PACKET_PROLONGATION = 'requestPacketProlongation';
const RECEIVE_PACKET_PROLONGATION = 'receivePacketProlongation';
const CANCEL_FETCHING_PACKET_PROLONGATION = 'cancelFetchingPacketProlongation';
// ------------------------------------
// Actions
// ------------------------------------

export const fetchPackets = () => (dispatch) => {
    const withPath = httpBuildQuery({
        with: ['servicesTemplates', 'packetCycles.cycle', 'packetCycles.clientPacketCycles'],
    });
    dispatch(requestPackets());
    return api(`/v4/admin/packets?${withPath}`, {
        method: 'GET'
    }, dispatch)
        .then(res => {
            if (res && res.packets != null && Array.isArray(res.packets)) {
                return dispatch(receivePackets(res.packets));
            } else {
                return  dispatch(cancelFetchingPackets());
            }
        });
};

export const fetchCycles = () => (dispatch) => {
    dispatch(requestCycles());
    return api('/v4/admin/cycles', {
        method: 'GET'
    }, dispatch)
        .then(res => {
            dispatch(receiveCycles(res));
        }).catch((err) => {
            dispatch(cancelFetchingCycles());
            return err;
        });
}

export const fetchPacket = (id) => (dispatch) => {
    const withPath = httpBuildQuery({
        with: ['servicesTemplates', 'packetCycles.cycle', 'packetCycles.clientPacketCycles'],
    });
    id && dispatch(requestPacket());
    return id && api('/v4/admin/packets/'+ id + `?${withPath}`, {
        method: 'GET'
    }, dispatch)
        .then(res => {
            if (res) {
                dispatch(receivePacket(res));
            } else {
                dispatch(cancelFetchingPacket());
            }
        });
};

export const fetchPacketProlongation = (label) => (dispatch) => {
    label && dispatch(requestPacketProlongation());
    return label && api('/v4/admin/prolongations/settings/service/'+ label, {
        method: 'GET'
    }, dispatch)
        .then(res => {
            if (res) {
                dispatch(receivePacketProlongation(res, label));
            } else {
                dispatch(cancelFetchingPacketProlongation());
            }
        }).catch(() => {
            dispatch(cancelFetchingPacketProlongation());
            return false;
        });
};

export const updatePacketProlongation = (label) => (dispatch) => {
    label && dispatch(requestPacketProlongation());
    return label && api('/v4/admin/prolongations/settings/service/'+ label, {
        method: 'PUT'
    }, dispatch)
        .then(res => {
            if (res) {
                dispatch(receivePacketProlongation(res, label));
            } else {
                dispatch(cancelFetchingPacketProlongation());
            }
        }).catch(() => {
            dispatch(cancelFetchingPacketProlongation());
            return false;
        });
};

export const storePacket = (data) => (dispatch) => {
    const withPath = httpBuildQuery({
        with: ['servicesTemplates', 'packetCycles.cycle', 'packetCycles.clientPacketCycles'],
    });
    if (data) {
        dispatch(requestPacket());
        if (data?.icon) {
            return api('/v4/admin/svg-images', {
                    method: 'POST',
                    body: data?.icon
                },
                dispatch, true)
                .then(response => {
                    if (response) {
                        delete data.icon;
                        data.icon_id = response?.id;
                        return api(`/v4/admin/packets?${withPath}`, {
                            method: 'POST',
                            body: JSON.stringify(data)
                        }, dispatch)
                            .then(res => {
                                if (res) {
                                    dispatch(receiveCreatePacket(res));
                                    return res?.id;
                                } else {
                                    dispatch(cancelFetchingPacket());
                                    return false;
                                }
                            })
                            .catch(() => {
                                dispatch(cancelFetchingPacket());
                                return false;
                            });
                    } else {
                        dispatch(cancelFetchingPacket());
                        return false;
                    }
                });
        } else {
            return api(`/v4/admin/packets?${withPath}`, {
                method: 'POST',
                body: JSON.stringify(data)
            }, dispatch)
                .then(res => {
                    if (res) {
                        dispatch(receiveCreatePacket(res));
                        return res?.id;
                    } else {
                        dispatch(cancelFetchingPacket());
                        return false;
                    }
                })
                .catch(() => {
                    dispatch(cancelFetchingPacket());
                    return false;
                });
        }
    } else {
        return null;
    }
};

export const updatePacket = (id, data) => (dispatch) => {
    const withPath = httpBuildQuery({
        with: ['servicesTemplates', 'packetCycles.cycle', 'packetCycles.clientPacketCycles'],
    });
    if (id && data) {
        dispatch(requestPacket());
        if (Object.prototype.hasOwnProperty.call(data, 'icon') && data?.icon) {
            return api('/v4/admin/svg-images', {
                    method: 'POST',
                    body: data?.icon
                },
                dispatch, true)
                .then(response => {
                    if (response) {
                        delete data.icon;
                        data.icon_id = response?.id;
                        return api('/v4/admin/packets/' + id + `?${withPath}`, {
                            method: 'PUT',
                            body: JSON.stringify(data)
                        }, dispatch)
                            .then(res => {
                                if (res) {
                                    dispatch(receiveUpdatePacket(res));
                                    return true;
                                } else {
                                    dispatch(cancelFetchingPacket());
                                    return false;
                                }
                            })
                            .catch(() => {
                                dispatch(cancelFetchingPacket());
                                return false;
                            });
                    } else {
                        dispatch(cancelFetchingPacket());
                        return false;
                    }
                });
        } else if (Object.prototype.hasOwnProperty.call(data, 'icon') && data?.icon === null) {
            delete data.icon;
            data.icon_id = null;
            return api('/v4/admin/packets/' + id + `?${withPath}`, {
                method: 'PUT',
                body: JSON.stringify(data)
            }, dispatch)
                .then(res => {
                    if (res) {
                        dispatch(receiveUpdatePacket(res));
                        return true;
                    } else {
                        dispatch(cancelFetchingPacket());
                        return false;
                    }
                })
                .catch(() => {
                    dispatch(cancelFetchingPacket());
                    return false;
                });
        } else {
            return api('/v4/admin/packets/' + id + `?${withPath}`, {
                method: 'PUT',
                body: JSON.stringify(data)
            }, dispatch)
                .then(res => {
                    if (res) {
                        dispatch(receiveUpdatePacket(res));
                        return true;
                    } else {
                        dispatch(cancelFetchingPacket());
                        return false;
                    }
                })
                .catch(() => {
                    dispatch(cancelFetchingPacket());
                    return false;
                });
        }
    }
};

export const bulkUpdatePackets = (typesDataUpdate) => (dispatch) => {
    typesDataUpdate && dispatch(requestPackets());
    return typesDataUpdate && api('/v4/admin/expenses/types/bulk-update', {
        method: 'PUT',
        body: JSON.stringify(typesDataUpdate)
    }, dispatch)
        .then(res => {
            if (res && res.packets != null && Array.isArray(res.packets)) {
                dispatch(receivePackets(res.packets));
            } else {
                dispatch(cancelFetchingPackets());
                return false;
            }
        })
        .catch(() => {
            dispatch(cancelFetchingPackets());
            return false;
        });
}

export const deletePacket = (id) => (dispatch) => {
    id &&  dispatch(requestPacket());
    return id && api('/v4/admin/packets/' + id, {
        method: 'DELETE'
    }, dispatch)
        .then(res => {
            if (res) {
                dispatch(receiveDeletedPacket(id));
                return true;
            } else {
                dispatch(cancelFetchingPacket());
                return false;
            }
        });
};

export const clearPackets = () => (dispatch) => {
    dispatch(receivePackets(null));
}

export const clearPacket = () => (dispatch) => {
    dispatch(receivePacket(null));
}

function cancelFetchingPackets() {
    return {
        type: CANCEL_FETCHING_PACKETS
    };
}

function cancelFetchingPacket() {
    return {
        type: CANCEL_FETCHING_PACKET
    };
}

function cancelFetchingPacketProlongation() {
    return {
        type: CANCEL_FETCHING_PACKET_PROLONGATION
    };
}

function requestPackets() {
    return {
        type: REQUEST_PACKETS
    };
}

function requestCycles() {
    return {
        type: REQUEST_CYCLES
    };
}

function receiveCycles(res) {
    return {
        type: RECEIVE_CYCLES,
        payload: res

    };
}

function cancelFetchingCycles() {
    return {
        type: CANCEL_FETCHING_CYCLES
    };
}

function requestPacket() {
    return {
        type: REQUEST_PACKET
    };
}

function requestPacketProlongation() {
    return {
        type: REQUEST_PACKET_PROLONGATION
    };
}

function receivePackets(res) {
    return {
        type: RECEIVE_PACKETS,
        payload: res
    };
}

function receivePacket(res) {
    return {
        type: RECEIVE_PACKET,
        payload: res
    };
}

function receivePacketProlongation(res, label) {
    return {
        type: RECEIVE_PACKET_PROLONGATION,
        payload: {
            prolongation: res,
            label: label
        }
    };
}

function receiveCreatePacket(res) {
    return {
        type: RECEIVE_CREATE_PACKET,
        payload: res
    };
}

function receiveUpdatePacket(res) {
    return {
        type: RECEIVE_UPDATED_PACKET,
        payload: res
    };
}

function receiveDeletedPacket(id) {
    return {
        type: RECEIVE_DELETED_PACKET,
        payload: id
    };
}

export const actions = {
    fetchPackets,
    fetchPacket,
    clearPacket,
    clearPackets,
    storePacket,
    updatePacket,
    deletePacket,
    bulkUpdatePackets,
    fetchPacketProlongation,
    updatePacketProlongation,
    fetchCycles
};
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [REQUEST_PACKET_PROLONGATION]: (state) => {
        return ({ ...state, fetchingProlongationSettings: true });
    },
    [CANCEL_FETCHING_PACKET_PROLONGATION]: (state) => {
        return ({ ...state, fetchingProlongationSettings: false });
    },
    [RECEIVE_PACKET_PROLONGATION]: (state, action) => {
        const packet = _.cloneDeep(state.packet);
        if (packet?.label === action?.payload?.label) {
            packet.prolongation = action.payload?.prolongation;
            return ({
                ...state,
                packet: packet,
                fetchingProlongationSettings: false
            });
        } else if (!packet && action?.payload?.label) {
            return ({
                ...state,
                packetProlongationSettings: {
                    label: action?.payload?.label,
                    prolongation: action.payload?.prolongation
                },
                fetchingProlongationSettings: false
            });
        } else {
            return ({
                ...state,
                fetchingProlongationSettings: false
            });
        }
    },

    [REQUEST_PACKETS]: (state) => {
        return ({ ...state, fetchingPackets: true });
    },
    [CANCEL_FETCHING_PACKETS]: (state) => {
        return ({ ...state, fetchingPackets: false });
    },
    [RECEIVE_PACKETS]: (state, action) => {
        return ({
            ...state,
            packets: action.payload,
            fetchingPackets: false
        });
    },

    [REQUEST_CYCLES]: (state) => {
        return ({ ...state, fetchingCycles: true });
    },
    [CANCEL_FETCHING_CYCLES]: (state) => {
        return ({ ...state, fetchingCycles: false });
    },
    [RECEIVE_CYCLES]: (state, action) => {
        return ({
            ...state,
            cycles: action.payload.items,
            cyclesTotal: action.payload.total,
            fetchingCycles: false
        });
    },

    [REQUEST_PACKET]: (state) => {
        return ({ ...state, fetchingPacket: true });
    },
    [CANCEL_FETCHING_PACKET]: (state) => {
        return ({ ...state, fetchingPacket: false });
    },
    [RECEIVE_PACKET]: (state, action) => {
        // packets
        return ({
            ...state,
            packet: action.payload,
            fetchingPacket: false
        });
    },
    [RECEIVE_CREATE_PACKET]: (state, action) => {
        let packets = _.cloneDeep(state.packets);
        if (packets) {
            packets = [
                ...packets,
                action.payload
            ].sort((a, b) => a.sort - b.sort);
        }

        return ({
            ...state,
            packet: action.payload,
            packets: packets,
            fetchingPacket: false
        });
    },
    [RECEIVE_UPDATED_PACKET]: (state, action) => {
        let packets = _.cloneDeep(state.packets);
        if (packets) {
            packets = [
                ...packets.map(packet => {
                    if (packet?.id === action.payload?.id) {
                        return {
                            ...packet,
                            ...action.payload
                        }
                    } else {
                        return packet;
                    }
                }).sort((a, b) => a.sort - b.sort)
            ]
        }

        return ({
            ...state,
            packet: action.payload,
            packets: packets,
            fetchingPacket: false
        });
    },
    [RECEIVE_DELETED_PACKET]: (state, action) => {
        let packets = _.cloneDeep(state.packets);
        if (packets) {
            packets = [
                ...packets?.filter(packet => {
                    return packet?.id !== action.payload;
                })
            ]
        }

        return ({
            ...state,
            packet: action.payload,
            packets: packets,
            fetchingPacket: false
        });
    },

};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    packets: null,
    packet: null,
    packetProlongationSettings: null,
    fetchingPackets: false,
    fetchingPacket: false,
    fetchingProlongationSettings: false,
    cycles: null,
    cyclesTotal: 0,
    fetchingCycles: false
};

export default function PacketsReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
