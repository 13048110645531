import React from 'react';
import classes from './CommonInfoBody.module.scss';
import PropTypes from 'prop-types';

const CommonInfoBody = ({ navbar, header, body, bodyStyles = [] }) => {
    return (
        <section className={classes.wrapCommonInfo}>
            <div className={classes.headerCommon}>
                {
                    header
                }
                {
                    navbar
                }
            </div>
            <div className={classes.bodyCommon + bodyStyles.map(item => ' ' + classes[item]).join('')}>{ body }</div>
        </section>
    )
};

CommonInfoBody.propTypes = {
    navbar: PropTypes.node,
    header: PropTypes.node,
    body: PropTypes.node
};

export default CommonInfoBody;
